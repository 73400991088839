// Constants
import { AppValues } from '@/Constants/Global/AppValues'
import { Component } from '@/Constants/Global/Component'

// Helpers
import { InputButton } from '@/Helpers/Components/DataForm/InputButton'
import { InputNumber } from '@/Helpers/Components/DataForm/InputNumber'
import { InputSelect } from '@/Helpers/Components/DataForm/InputSelect'
import { InputText }   from '@/Helpers/Components/DataForm/InputText'

// Form Export
export default {
	Equipments: {
		params: {
			currentTab: AppValues.Strings.TEXT_EQUIPMENTS.toLowerCase(),
			numColumns: 2,
		},
		tabs: [
			{
				header: {
					key: AppValues.Strings.TEXT_EQUIPMENTS.toLowerCase(),
					text: {
						onInsert: 'Nuevo Equipo',
						onUpdate: 'Actualizar Equipo'
					}
				},
				columns: [
					new InputText('code', 'Código')
						.setParamsReadOnly(Component.Actions.INSERT)
					,
					new InputNumber('hourmeter', 'Horometro')
						.setParamsRange(0)
						.setParamsVisible(Component.Actions.INSERT)
					,
					new InputText('serie', 'Serie'),
					new InputText('name', 'Descripción'),
					new InputText('mark', 'Marca'),
					new InputText('groupEquipment', 'Grupo Equipo '),
					new InputText('groupArticle', 'Grupo Artículo'),
					new InputSelect('typeEquipment', 'Tipo Equipo', Object.values(AppValues.EquipmentTypes)),
					new InputSelect('typeArticle', 'Tipo Artículo', Object.values(AppValues.ArticleTypes)),
					new InputButton('storage', 'Ubicación')
						.setParamsIcon('pen')
					,
					new InputNumber('year', 'Año')
						.setParamsRange(1950, 2030)
					,
					new InputSelect('isValid', '¿Esta Activa?', ['Sí', 'No'])
						.setParamsVisible(Component.Actions.UPDATE)
				]
			}
		]
	},
	WorkAreas: {
		params: {
			currentTab: AppValues.Strings.TEXT_WORKAREAS.toLowerCase(),
			numColumns: 2,
		},
		tabs: [
			{
				header: {
					key: AppValues.Strings.TEXT_WORKAREAS.toLowerCase(),
					text: {
						onInsert: 'Nueva Área de Trabajo',
						onUpdate: 'Actualizar Área de Trabajo'
					}
				},
				columns: [
					new InputText('name', 'Nombre'),
					new InputButton('leadername', 'Líder')
						.setParamsIcon('pen')
					,
					new InputSelect('isValid', '¿Esta Activa?', ['Sí', 'No'])
						.setParamsVisible(Component.Actions.UPDATE)
				]
			}
		]
	}
}