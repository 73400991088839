// Helpers
import { BaseInput } from '@/Helpers/Components/DataForm/BaseInput'

// Class Export
export class InputNumber extends BaseInput {
	constructor(key: string, label: string) {
		super()
		this.Key   = key
		this.Label = label
		this.setParamsType('number')
		this.setParamsState(this.resolveInputState)
	}

	public resolveInputState(self: InputNumber) {
		if (self.Value === '') return null
		const value = parseInt(self.Value)
		const range = self.Params.range
		
		if (range) {
			const { min, max } = range
			if (min && max) {
				return value > (min - 1) && value < (max + 1)
			}
			else {
				return value > (min - 1)
			}
		}
		return true
	}

	public setParamsRange(min?: number, max?: number) {
		this.Params.range = { min, max }
		if (!max) delete this.Params.range.max
		return this
	}
}